import React, { useContext, useEffect } from "react";
import AppContext from "@src/context";
import Seo from "@components/utility/SEO";
import { KfHeroUtility, KfAnchorLink } from "@klickinc/kf-react-components";

const ChallengingExpectations = () => {
	const ctx = useContext(AppContext);

	useEffect(() => {
		ctx.setPageClass("page--transcript page--transcript-challenging-expectations");
	}, []);

	return (
		<>
			<section className="transcript-section">
				<div className="container container--inner">
					<KfHeroUtility addedClass="heading-hero-blue heading">
						Transcript
					</KfHeroUtility>
					<p className="uppercase mb-4">Jessica Roth is the mom of a patient and her testimonial reflects real life experiences. She was compensated for the use of her experiences.</p>
					<h2 className='orange-heading'>Challenging Expectations</h2>
					<div className="script">
						<div className="script--body">
							<p><span className="name">Jessica:</span> <span className="copy">People think that, oh, you're in a wheelchair, they're not smart, they can't go to school, and they can't go to college. And that's absolutely not true. Most of the boys are extremely intelligent, and they absolutely can go to school and thrive. And they can go to college too, you know. And so that's something that I hear a lot.</span></p>
							<p><span className="name">Jessica:</span> <span className="copy">I've heard people say that they can't be in extracurricular activities, that they're not able to do all that. And that's not true either. We've found that the leaders, like for Scouts for one thing, they adapted&#8212;they can adapt pretty much anything.</span></p>
							<p><span className="name">Jessica:</span> <span className="copy">And so he can be a part of these activities. And so it's so important to not listen to some of these misconceptions. And then driving; well, you can adapt a vehicle to drive. Can you go to college? Well, yeah, we can hire attendants to go to college. And can you have relationships and get married? Yes. I know a young man that lives in our community, and he has two kids, and he's living a wonderful life. So those are the misconceptions that we get a lot, and they're just not true.</span></p>
							<p className="highlighted-copy mt-10">To get connected with a Peer Navigator, please contact us at &nbsp; <KfAnchorLink url="mailto:peernavigator@ptcbio.com" linkClass="underline font-bold">peernavigator@ptcbio.com</KfAnchorLink> <span className="mx-1">or</span> <KfAnchorLink url="tel:(866) 282-5878" linkClass="underline font-bold">(866) 282-5878</KfAnchorLink>.</p>
						</div>
					</div>
				</div>
			</section>
		</>
	);
};

export default ChallengingExpectations;

export function Head() {
	return (
		<Seo title="Video Transcript: Challenging Expectations - EMFLAZA® (deflazacort)" addSocialMeta={false}>
			<meta name="robots" content="noindex" />
		</Seo>
	);
};
